import * as React from "react"
import ContentHeader from "../components/content-header"
import Footer from "../components/footer"

export default (): JSX.Element => {
	return (
		<div className="container">
			<ContentHeader />
			<br />
			<br />
			<h1>Impressum</h1>

			<p>
				Flughafen Stuttgart GmbH
				<br />
				vertreten durch die Geschäftsführung
				<br />
				Walter Schoefer (Sprecher)
				<br />
				Ulrich Heppe

			</p>
			<p>
				Flughafenstraße 32
				<br />
				70629 Stuttgart
			</p>
			<p>
				Postfach 23 04 61
				<br />
				70624 Stuttgart
			</p>
			<p>
				Telefon: +49 711 948-0
				<br />
				Telefax: +49 711 948-2241
				<br />
				E-Mail:{" "}
				<a href="mailto:info@stuttgart-airport.com">info@stuttgart-airport.com</a>
			</p>
			<p>
				Umsatzsteuer-Identifikationsnummer: DE 147856205
				<br />
				Registergericht: Amtsgericht Stuttgart HRB Nr. 969
			</p>

			<h2>Rechtliche hinweise Urheber-, Marken- und sonstige Schutzrechte</h2>

			<p>Alle Rechte vorbehalten.</p>
			<p>
				Alle Texte, Bilder, Grafiken, Audio-Dateien, Animationen und Videos sowie
				alle sonstigen Inhalte dieser Webseiten sowie deren Anordnung und Gestaltung
				unterliegen dem Schutz des Urheberrechts und anderer Schutzgesetze. Der
				Inhalt dieser Webseiten darf nicht zu kommerziellen und gewerblichen Zwecken
				kopiert, verbreitet, verändert oder Dritten zugänglich gemacht werden.
			</p>
			<p>
				Soweit nicht anders angegeben, sind alle Bezeichnungen und/oder Zeichen auf
				diesen Webseiten rechtlich geschützt. Dies gilt insbesondere für Marken,
				Typenschilder, Firmenlogos und Embleme der Flughafen Stuttgart GmbH. Auch die
				Bezeichnungen und/oder Zeichen anderer Firmen und/oder Organisationen, die
				auf den Webseiten der Flughafen Stuttgart GmbH dargestellt werden, können
				rechtlich geschützt sein.
			</p>
			<p>
				Diese Webseiten dürfen nicht in fremden "Frames" dargestellt werden. Es ist
				unzulässig, Flugverbindungen und Flugdaten oder sonstige Informationen aus
				diesen Webseiten automatisiert durch "Spider", "Crawler", "Bots" oder
				sonstige Techniken auszulesen ("Screen Scraping"), insbesondere wenn dies
				dazu dient, ausgelesene Informationen in fremden Datenverarbeitungsdiensten
				oder -angeboten zu nutzen und weiterzuverarbeiten (z.B. durch das
				Bereitstellen von sog. „Apps“, die auf diese Daten zugreifen können).
			</p>
			<p>
				Durch diese Webseiten wird keine Lizenz zur Nutzung des geistigen Eigentums
				der Flughafen Stuttgart GmbH, Unternehmen der Flughafen Stuttgart GmbH, oder
				von Dritten, erteilt.
			</p>
			<p>
				Ausgenommen davon sind die Seiten unter der Rubrik „Presse“. Das dort
				angebotene Text- und Bildmaterial steht zur redaktionellen Verwendung im
				Rahmen der gesetzlichen Bestimmungen zur freien Verfügung. Bei
				Vervielfältigung dieser Inhalte ist ausdrücklich auf die Urheber- und
				Eigentumsrechte der Flughafen Stuttgart GmbH hinzuweisen. Die Inhalte dürfen
				nicht verändert oder ohne schriftliche Genehmigung der Flughafen Stuttgart
				GmbH auf anderen Internetseiten genutzt werden. Die in diesem
				Internetauftritt wiedergegebenen Inhalte wie zum Beispiel Bilder können auch
				dem Urheberrecht Dritter unterliegen.
			</p>
			<p>
				<strong>
					Unverbindlichkeit der Information, Änderungsvorbehalt, Technischer Hinweis
				</strong>
			</p>
			<p>
				Diese Webseiten werden mit größtmöglicher Sorgfalt erstellt und gepflegt. Die
				Flughafen Stuttgart GmbH übernimmt jedoch für deren Richtigkeit,
				Vollständigkeit, Aktualität oder laufende Verfügbarkeit keinerlei Gewähr und
				schließt daher eine Haftung für Verluste oder Schäden jeder Art, die direkt
				oder indirekt aus der Nutzung dieser Webseiten entstehen, aus. Dies gilt auch
				für alle flugplanbezogenen Daten.
			</p>
			<p>
				Alle Angaben sind freibleibend und unverbindlich. Die Flughafen Stuttgart
				GmbH behält sich vor, ihre Webseiten oder Teile davon jederzeit zu ändern, zu
				ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig
				einzustellen.
			</p>
			<p>
				Wir weisen außerdem darauf hin, dass die Darstellungsmöglichkeiten unserer
				Webseiten auf Ihrem Computer system- und softwareabhängig sind.
			</p>
			<p>
				<strong>Verweise [Links, Internetangebote, Dienste Dritter]</strong>
			</p>
			<p>
				Von den Webseiten der Flughafen Stuttgart GmbH aus wird durch Links
				unmittelbar oder mittelbar auf andere Internetauftritte verwiesen. Über
				sogenannte Plugins wird z.B. auch auf Seiten von sozialen Netzwerken
				verwiesen, die über diese Plugins erreicht werden können bzw. eingebunden
				sind (z.B. Twitter und Facebook). Der Inhalt und die Gestaltung dieser
				Angebote unterliegen nicht der Kontrolle oder dem Einfluss der Flughafen
				Stuttgart GmbH. Für den Inhalt und die Gestaltung der verlinkten bzw. durch
				solche Plugins eingebundenen Seiten sind ausschließlich deren Betreiber
				verantwortlich. Die Flughafen Stuttgart GmbH übernimmt deshalb dafür keine
				Haftung.
			</p>

			<Footer suffix="de" />
		</div>
	)
}
